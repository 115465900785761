//@ts-nocheck
import classNames from "classnames";
import moment from "moment";
import React, { useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import QRCode from "react-qr-code";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import TopBar from "../components/TopBar";
import { formToJson } from "../data/api";
import { logout, useIsStaff } from "../data/auth";
import {
  QUERY_KEY as StudentQueryKey,
  getProfile,
  getStudent,
  verifyStudentForEvent,
} from "../data/student";
import { setApiStatus } from "../utils/apiStatus";

const Profile = () => {
  const navigate = useNavigate();
  const { id: studentId } = useParams();
  const isStaffUser = useIsStaff();

  const queryClient = useQueryClient();
  const [verifyStatus, setVerifyStatus] = useState({
    loading: false,
    showAlert: false,
  });

  const { data: profileResponse, ...profileMeta } = useQuery(
    StudentQueryKey,
    getProfile,
    { staleTime: Infinity }
  );
  const { data: studentResponse, ...studentMeta } = useQuery(
    [`${StudentQueryKey}/${studentId}`, studentId],
    getStudent,
    { staleTime: Infinity, enabled: !!studentId }
  );

  const loading =
    profileMeta.isLoading || (!!studentId && studentMeta.isLoading);
  const profile = studentId ? studentResponse?.data : profileResponse?.data;

  const handleVerify = (e) => {
    e.preventDefault();
    const data = formToJson(e);
    setApiStatus(verifyStudentForEvent, data, setVerifyStatus);
    queryClient.invalidateQueries([
      `${StudentQueryKey}/${studentId}`,
      studentId,
    ]);
  };

  return (
    <div>
      <TopBar title="Profile" padding={2} />
      <div className="container" style={{ marginTop: "-8em" }}>
        <div className="bg-white shadow rounded p-2">
          <div>{isStaffUser && "My role: Stage Manager / Staff"}</div>
          {profileResponse?.status === 400 && !studentId ? (
            <div>-</div>
          ) : (
            <div>
              <div className="d-flex align-items-center">
                {loading ? (
                  <Skeleton />
                ) : (
                  <img
                    src={profile.photo}
                    alt={profile.name}
                    className="img-fluid"
                    style={{ width: "128px" }}
                  />
                )}
                <div>
                  <h4>{loading ? <Skeleton /> : profile.name}</h4>
                  <h6 className="d-flex align-items-center">
                    <span className="material-icons me-1">account_balance</span>{" "}
                    {loading ? <Skeleton /> : profile.college.name}
                  </h6>

                  <div className="m-0 p-0 d-flex align-items-center">
                    <span className="material-icons me-1">phone</span>{" "}
                    {loading ? <Skeleton /> : profile.phone_number}
                  </div>
                  <div className="m-0 p-0 d-flex align-items-center">
                    <span className="material-icons me-1">calendar_today</span>{" "}
                    {loading ? (
                      <Skeleton />
                    ) : (
                      moment(profile.date_of_birth).format("DD MMM YYYY")
                    )}
                  </div>
                  <div className="m-0 p-0 d-flex align-items-center">
                    <span className="material-icons me-1">school</span>{" "}
                    {loading ? <Skeleton /> : profile.class_name}
                  </div>
                </div>
              </div>
              <hr />
              <h4>Events</h4>
              {loading ? (
                <Skeleton />
              ) : (
                <Table hover striped>
                  <tbody>
                    {profile.events.map((e) => (
                      <tr>
                        <td valign="middle">
                          <div className="d-flex align-items-center">
                            <span
                              className={classNames("material-icons me-2", [
                                (profile?.verified_event_ids || []).includes(
                                  e.id
                                )
                                  ? "text-success"
                                  : "text-danger",
                              ])}
                            >
                              {(profile?.verified_event_ids || []).includes(
                                e.id
                              )
                                ? "verified"
                                : "error"}
                            </span>
                          </div>
                        </td>
                        <td>{e.category.name}</td>
                        <td>{e.name}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
              <hr />
              {!isStaffUser && (
                <>
                  <h4>QR Code</h4>
                  {loading || !profileResponse?.data ? (
                    <Skeleton />
                  ) : (
                    <div>
                      <QRCode
                        value={JSON.stringify({
                          id: profileResponse.data.id,
                          phone_number: profileResponse.data.phone_number,
                        })}
                      />
                    </div>
                  )}
                </>
              )}
              {isStaffUser && (
                <>
                  <div className="d-flex mb-2">
                    {profile && (
                      <a
                        className="btn btn-secondary border flex-grow-1 me-1"
                        target="_blank"
                        href={profile?.id_proof}
                      >
                        <div className="d-flex align-items-center justify-content-center">
                          <span className="material-icons">fingerprint</span>
                          <span>ID Proof</span>
                        </div>
                      </a>
                    )}
                  </div>
                  {profile && (
                    <>
                      <hr />
                      <Form onSubmit={handleVerify}>
                        <Form.Group>
                          <Form.Control
                            hidden
                            name="student"
                            value={profile.id}
                          />
                          <Form.Label>Spot Register for Event</Form.Label>
                          <Form.Select name="event">
                            {profile.events.map((e, i) => (
                              <option value={e.id}>{e.name}</option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                        <div className="d-grid mt-2">
                          <Button type="submit" className="text-white">
                            Register
                          </Button>
                        </div>
                      </Form>
                    </>
                  )}
                </>
              )}
            </div>
          )}
          <div className="d-flex justify-content-around">
            <div
              onClick={() => navigate("/change_password")}
              className="text-center btn btn-primary"
            >
              Change Password
            </div>
            <div onClick={() => logout()} className="text-center btn btn-primary">
              Logout
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
