//@ts-nocheck
import React, { useState } from "react";
import Carousel from "react-bootstrap/esm/Carousel";
import Card from "react-bootstrap/esm/Card";
import { useQuery } from "react-query";
import { QUERY_KEY as ScheduleQueryKey, listSchedule } from "../data/schedule";
import moment from "moment";
import { Link } from "react-router-dom";
import ScheduleCard from "../components/ScheduleCard";
import ResultCard from "../components/ResultCard";
import {
  QUERY_KEY as ResultQueryKey,
  listResults,
  collegeLeaderBoard,
  individualLeaderBoard,
} from "../data/result";
import { Table, Form } from "react-bootstrap";
import { FestDetails } from "../constants";
import { dateStrForFestival } from "../utils/date";
import {
  QUERY_KEY as CategoryQueryKey,
  listCategories,
} from "../data/category";
import BottomMenu from "../components/BottomMenu";
import { useIsStaff } from "../data/auth";

const Home = () => {
  const isStaff = useIsStaff();
  const now = moment();
  const params = {
    page_size: 5,
    after: now.format("YYYY-MM-DD hh:mm"),
  };
  const { data: upcomingSchedule, ...scheduleMeta } = useQuery(
    [ScheduleQueryKey, params],
    listSchedule,
    {
      staleTime: Infinity,
      refetchOnMount: false,
    }
  );

  const [collegeCategory, setCollegeLeaderBoardCategory] = useState(0);
  const [individualCategory, setIndividualLeaderBoardCategory] =
    useState("FEMALE");

  const { data: resultsResponse, ...resultsMeta } = useQuery(
    [ResultQueryKey, params],
    listResults,
    { staleTime: Infinity }
  );

  const { data: collegeLeaderBoardResponse } = useQuery(
    [`${ResultQueryKey}/CollegeLeaderboard`, { category: collegeCategory }],
    collegeLeaderBoard,
    { staleTime: Infinity }
  );
  const { data: individualLeaderBoardResponse } = useQuery(
    [
      `${ResultQueryKey}/individualLeaderBoard`,
      { category: individualCategory },
    ],
    individualLeaderBoard,
    { staleTime: Infinity }
  );

  const { data: categoryList } = useQuery(CategoryQueryKey, listCategories, {
    staleTime: Infinity,
  });

  return (
    <div className="bg-light page">
      <div className="bg-primary text-white">
        <div className="container py-4">
          <div className="d-flex">
            <div className="ms-2">
              <h4 className="m-0 p-0 text-bold"><strong>{FestDetails.name} </strong></h4>
              <p className="m-0 p-0">
                {FestDetails.venue} <br />
                {dateStrForFestival(FestDetails)} <br />
              </p>
            </div>
            <img
              alt="logo"
              src={FestDetails.logo}
              className="img-fluid rounded-circle shadow bg-white"
              style={{ height: "150px" }}
            />
          </div>
        </div>
        <div className="p-4">&nbsp;</div>
      </div>
      <div className="container" style={{ marginTop: "-5em" }}>
        <div className=" border-top border-white pt-2">
          <div className="d-flex justify-content-between fw-bold text-white">
            <div>Upcoming events</div>
            <Link
              to="/events"
              className="text-white"
              style={{ textDecoration: "none" }}
            >
              <div className="d-flex align-items-center">
                See all
                <span className="material-icons">navigate_next</span>
              </div>
            </Link>
          </div>
          <div className="mt-2">
            <Carousel controls={false} indicators={false} interval={null}>
              {scheduleMeta.isLoading ? (
                <Carousel.Item className="">
                  <div className="bg-white border shadow rounded p-4">
                    <ScheduleCard />
                  </div>
                </Carousel.Item>
              ) : (
                upcomingSchedule?.data.results.map((schedule, idx) => (
                  <Carousel.Item key={`schedule-card-${idx}`}>
                    <div className="bg-white shadow rounded">
                      <ScheduleCard schedule={schedule} />
                    </div>
                  </Carousel.Item>
                ))
              )}
            </Carousel>
            {(upcomingSchedule?.data?.results || []).length === 0 && (
              <div className="bg-white shadow rounded p-4 mb-2">
                Waiting for schedule to be published
              </div>
            )}
          </div>
        </div>
        <div className=" border-top border-white pt-2">
          <div className="d-flex justify-content-between fw-bold">
            <div>Results</div>
            <Link
              to="/events"
              className="text-primary"
              style={{ textDecoration: "none" }}
            >
              <div className="d-flex align-items-center">
                See all
                <span className="material-icons">navigate_next</span>
              </div>
            </Link>
          </div>
          <div className="mt-2">
            <Carousel controls={false} indicators={false} interval={null}>
              {resultsMeta.isLoading ? (
                <Carousel.Item className="">
                  <div className="bg-white border shadow rounded p-4">
                    <ResultCard />
                  </div>
                </Carousel.Item>
              ) : (
                (resultsResponse?.data?.results || []).map((result) => (
                  <Carousel.Item key="idx">
                    <div className="bg-white shadow rounded">
                      <ResultCard result={result} />
                    </div>
                  </Carousel.Item>
                ))
              )}
            </Carousel>
            {(resultsResponse?.data?.results || []).length === 0 && (
              <div className="bg-white shadow rounded p-4 mb-2">
                Waiting for results to be published
              </div>
            )}
          </div>
        </div>
        <div className=" border-top border-white pt-2">
          <div className="d-flex justify-content-between fw-bold">
            <div>Leaderboard</div>
            <Link
              to={`/leaderboard/${collegeCategory}`}
              className="text-primary"
              style={{ textDecoration: "none" }}
            >
              <div className="d-flex align-items-center">
                See all
                <span className="material-icons">navigate_next</span>
              </div>
            </Link>
          </div>
          <div className="mt-2">
            <Card>
              <Card.Body>
                <Card.Text>
                  <Form.Select
                    onChange={(e) => {
                      e.preventDefault();
                      setCollegeLeaderBoardCategory(e.target.value);
                    }}
                  >
                    <option value={0}>Overall</option>
                    {(categoryList?.data.results ?? []).filter(c => c.show_in_team_results).map((category) => (
                      <option value={category.id}>{category.name}</option>
                    ))}
                  </Form.Select>
                </Card.Text>
                <Card.Text>
                  {!!collegeLeaderBoardResponse?.data?.results && (
                    <Table striped>
                      {collegeLeaderBoardResponse?.data?.results
                        .slice(0, 10)
                        .map((row) => (
                          <tr>
                            <td>{row.team__college__name}</td>
                            <td>
                              <small>{row.points}</small>
                            </td>
                          </tr>
                        ))}
                    </Table>
                  )}
                  {collegeLeaderBoardResponse?.data?.results?.length === 0 && (
                    <div>Waiting for the first result to be announced.</div>
                  )}
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>
        {FestDetails.show_individual_leaderboard && (
          <div className=" border-top border-white pt-2">
            <div className="d-flex justify-content-between fw-bold">
              <div>Individual Leaderboard</div>
            </div>
            <div className="mt-2">
              <Card>
                <Card.Body>
                  <Card.Text>
                    <Form.Select
                      onChange={(e) => {
                        e.preventDefault();
                        setIndividualLeaderBoardCategory(e.target.value);
                      }}
                    >
                      <option value="FEMALE">Kalathilakam</option>
                      <option value="MALE">Kalaprathibha</option>
                      <option value="Chithrolsavam">Chithra Prathibha</option>
                      <option value="Sahithyolsavam">Sahithya Prathibha</option>
                      <option value="Nritholsavam">Nritha Prathibha</option>
                      <option value="Sangeetholsavam">Sangeetha Prathibha</option>
                      <option value="Drishya-Natakolsavam">Drisya-Nataka Prathibha</option>
                    </Form.Select>
                  </Card.Text>
                  <Card.Text>
                    {!!individualLeaderBoardResponse?.data?.results && (
                      <Table striped>
                        {individualLeaderBoardResponse?.data?.results
                          .slice(0, 10)
                          .map((row) => (
                            <tr>
                              <td>
                                {row.team__primary_student__name}
                                <br />
                                <div
                                  className="fw-light"
                                  style={{ fontSize: "0.7em" }}
                                >
                                  {row.team__college__name}
                                </div>
                              </td>
                              <td valign="middle">
                                <small>{row.points}</small>
                              </td>
                            </tr>
                          ))}
                      </Table>
                    )}
                    {individualLeaderBoardResponse?.data?.results?.length ===
                      0 && (
                      <div>Waiting for the first result to be announced.</div>
                    )}
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
        )}
      </div>

      <div className="text-center py-4">
        <div> Powered by </div>
        <a href="https://tiqr.events/" target="_blank" rel="noreferrer">
          <img src="/te_logo_text-svg.png" alt="ticker logo" width={"100px"} />
        </a>
      </div>
      {isStaff && (
        <div>
          <div style={{ height: "100px" }}>&nbsp;</div>
          <BottomMenu />
        </div>
      )}
    </div>
  );
};

export default Home;
